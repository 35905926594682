/* @import url("./font.css"); */

* {
  box-sizing: border-box;
}

.closeModal{
  background-color:red;
   cursor:pointer;
   font-size:25px;
   color:white; 
   display:flex;
   justify-content:center;
   align-items:center;
  position:absolute;
    right:-50px;
     top:0; 
    width:40px;
  height:40px;
        
}

.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
  float: right;
}
.pagination > .active > a{
  background-color: white ;
  border-color: blue ;
  color: #fff;
}
.pagination > li > a{
  border: 1px solid black ;
  padding: 5px 10px;
  margin: 4px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
  background-color: white ;
  color: black;
  border:2.2px solid blue;
  outline: none ;
}
.pagination > li > a, .pagination > li > span{
  color: grey
}
.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
  border-radius: 2px
}

body {
  margin: 0;
  font-size: 0.9rem;
  overflow-x: hidden;
  color: #393939;
  background-color: #fff;
  font-family: "Avenir", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.no-scroll {
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
div,
header,
section {
  margin: 0;
}

p,
textarea {
  white-space: pre-line;
}

button,
input {
  border: none;
  outline: none;
}

section {
  width: 100%;
  margin: 0;
}

.p-r {
  position: relative !important;
}
.absolute {
  position: absolute !important;
}

.no-padding {
  padding: 0 !important;
}
.p-10 {
  padding: 10px !important;
}
.p-20 {
  padding: 20px !important;
}
.p-40 {
  padding: 40px !important;
}
.p-t-40 {
  padding-top: 40px !important;
}
.p-r-5 {
  padding-right: 5px !important;
}
.p-l-5 {
  padding-left: 5px !important;
}

.no-margin {
  margin: 0 !important;
}
.margin-center {
  margin: 0 auto !important;
}
.m-l-5 {
  margin-left: 5px !important;
}
.m-l-10 {
  margin-left: 10px !important;
}
.m-l-20 {
  margin-left: 20px !important;
}
.m-r-5 {
  margin-right: 5px !important;
}
.m-r-10 {
  margin-right: 10px !important;
}
.m-r-15 {
  margin-right: 15px !important;
}
.m-t-10 {
  margin-top: 10px !important;
}
.m-t-20 {
  margin-top: 20px !important;
}
.m-t--20 {
  margin-top: -20px !important;
}
.m-t-40 {
  margin-top: 40px !important;
}
.m-t-5 {
  margin-top: 5px !important;
}
.m-b-5 {
  margin-bottom: 5px !important;
}
.m-b-10 {
  margin-bottom: 10px !important;
}
.m-b-20 {
  margin-bottom: 20px !important;
}
.m-b-40 {
  margin-bottom: 40px !important;
}
.m-b-50 {
  margin-bottom: 50px !important;
}

.no-border {
  border: none !important;
}
.bottom-border {
  border-bottom: 1px solid #c6c6c6 !important;
}
.no-wrap {
  flex-wrap: nowrap !important;
}
.wrap {
  flex-wrap: wrap !important;
}
.flex {
  display: flex !important;
}

.flex-column {
  display: flex !important;
  flex-direction: column;
}
.flex-md-row {
  display: flex !important;
  flex-direction: row;
}
.flex-row-left {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.flex-row-top {
  display: flex !important;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.flex-column-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.flex-h-center {
  justify-content: center !important;
}
.flex-v-center {
  align-items: center !important;
}
.pointer {
  cursor: pointer !important;
}
.underline {
  text-decoration: underline !important;
}
.center-text {
  text-align: center !important;
}
.right-text {
  text-align: right !important;
}
.space-between {
  justify-content: space-between !important;
}
.flex-end {
  justify-content: flex-end !important;
}
.flex-v-baseline {
  align-items: baseline !important;
}
.float-right {
  float: right !important;
}
.inline-block {
  display: inline-block !important;
}
.bold-text {
  font-weight: bold !important;
}
.border{
  border: 2px solid #0000000A;
}
.table-two {
  padding: 20px;
  background: white;
}
.table-three {
  /* padding: 20px; */
  padding-top: 0px;
  padding-right: 20px;
  padding-bottom: 0px;
  padding-left: 20px;
  background: white;
  /* width: 300px; */
  height: 242px;
  overflow: auto;
}
.table-two div {
  padding:10px 0;
  border-bottom: 1px solid #f3f3f3;
}

.max-w-400 {
  max-width: 400px !important;
}
.max-w-600 {
  max-width: 600px !important;
}
.max-w-800 {
  max-width: 800px !important;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-half,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  display: inline-block;
  position: relative;
  padding-right: 10px;
  padding-left: 10px;
  margin-bottom: 20px;
}
.col-1 {
  width: 8.33%;
}
.col-2 {
  width: 16.66%;
}
.col-3 {
  width: 25%;
}
.col-4 {
  width: 33.33%;
}
.col-5 {
  width: 41.65%;
}
.col-6,
.col-half {
  width: 50%;
}
.col-7 {
  width: 58.33%;
}
.col-8 {
  width: 66.66%;
}
.col-9 {
  width: 75%;
}
.col-10 {
  width: 83.33%;
}
.col-11 {
  width: 91.63%;
}
.col-12,
.w-100 {
  width: 100% !important;
  position: relative;
}
.w-md-75 {
  width: 65% !important;
}
.row {
  display: flex;
  flex-wrap: wrap;
  /* width: calc(100% + 20px); */
  position: relative;
  margin-right: -10px;
  margin-left: -10px;
}

.bg-white {
  background-color: #ffffff !important;
}
.bg-gray {
  background-color: #dddddd !important;
}
.bg-light-gray {
  background-color: #f5f7f9 !important;
}

.card {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 1px;
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  transition: all 0.2s ease;
  position: relative;
  margin-bottom: 2px;
  width: 100%;
  word-wrap: none;
}
.card .card-header {
  background: transparent;
  border-radius: 0px;
  border-bottom: 0px;
  padding: 20px 20px 7px 20px;
  position: relative;
  z-index: 3;
  min-height: 48px;
}
.card .card-header.separator:after {
  content: "";
  height: 1px;
  background: rgba(0, 0, 0, 0.08);
  left: 16px;
  right: 16px;
  position: absolute;
  bottom: 0;
}
.card .card-header + .card-body {
  padding-top: 0;
}
.card .card-header .card-title {
  font-family: "Montserrat";
  text-transform: uppercase;
  display: inline-block;
  letter-spacing: 0.06em;
  font-size: 10.5px;
  font-weight: 500;
  margin: 0;
  padding: 0;
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-text-stroke: 0px;
  filter: alpha(opacity=40);

  transition: opacity 0.3s ease;

  -webkit-filter: alpha(opacity=40);
}
.card .card-header .card-controls {
  float: left;
  margin-top: -3px;
}
.card .card-header .card-controls ul {
  margin: 0;
  padding: 0;
}
.card .card-header .card-controls ul li {
  display: inline-block;
  list-style: none;
  line-height: 0;
}
.card .card-header .card-controls:after {
  content: "";
  display: table;
  clear: both;
}
.card .card-header.card-header-hover .card-title {
  opacity: 0.4;
}
.card .card-header.card-header-hover:hover .card-title {
  opacity: 1;
}
.card.card-default {
  border: 1px solid rgba(0, 0, 0, 0.07);
}
.card.card-default > .card-header {
  color: #333;
}
.card.card-bordered {
  border: 1px solid rgba(230, 230, 230, 0.7);
}
.card.card-borderless {
  border: none;
}
.card.card-condensed .card-header {
  padding: 13px 13px 0 13px;
  min-height: 30px;
}
.card.card-condensed .card-header .card-title {
  opacity: 0.4;
}
.card.card-condensed .card-body {
  padding: 13px;
}
.card.card-hover .card-header .card-title {
  opacity: 0.4;
}
.card.card-hover:hover .card-header .card-title {
  opacity: 1;
}
.card.card-transparent {
  background: transparent;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.card.card-transparent .card-body {
  background: transparent;
}
.card.full-height {
  height: calc(100%);
}
.card.full-height .card-body {
  height: auto;
  width: 100%;
  height: 100%;
}
.card.card-featured {
  -webkit-box-shadow: -1px 1px 3px 0px rgba(121, 129, 135, 0.14);
  box-shadow: -1px 1px 3px 0px rgba(121, 129, 135, 0.14);
  width: calc(100% - 50px);
  float: left;
}
.card.card-featured .card-title h4 {
  font-family: "Montserrat";
  font-size: 16px;
  text-transform: uppercase;
  color: #f0f0f0;
}
.card.card-featured .card-body h3 {
  line-height: 34px;
  font-size: 26px;
}

.card.hover-fill:hover {
  background: #f0f0f0;
}
.card.hover-stroke:hover {
  border: 1px solid #e6e6e6;
}
.card .card-body {
  padding: 20px;
}
.card .card-body.no-padding .row {
  margin-left: 0;
  margin-right: 0;
}
.card .card-body.no-bottom-padding {
  padding-bottom: 0;
}
.card .card-body.no-top-padding {
  padding-top: 0;
}
.card .card-body .title {
  margin-top: 0px;
}
.card .card-body.scrollable {
  margin-bottom: 20px;
}

.mr-20 {
  margin-right: 20px !important;
}
.mr_10 {
  margin-right: 10px;
}
/* Notification modal */
.notification_modal {
  position: absolute;
  left: 65%;
  font-weight: normal !important;
  background: white;
  padding: 10px 20px;
  box-shadow: 5px 3px 22px #958e8e29;
  width: 35%;
  max-height: 500px;
  color: #393939;
  /* text-align: center; */
  z-index: 9;
  overflow-y: scroll
}
.notification_modal > div {
  padding: 10px 0;
  border-bottom: 1px solid #f3f3f3;
}

.notification_modal1 {
   position: fixed; 
  /* left: 0%; */
   /* font-weight: normal !important; */
  background: white;
  /* padding: 10px 20px; */
  box-shadow: 5px 3px 22px #958e8e29;
  /* width: 100%;  */
  max-height: 500px; 
  color: #393939; 
  display: block;
   width: 100%;
  height: 100%;
   top: 0;
   left: 0;

  background-color:#1C811C66;
  /* position: relative; */
  z-index: 1000;
  /* padding: 100px 30%; */
  overflow: auto;
  /* z-index: 1000;  */
}
.notification_hover:hover{
  background-color:rgb(213, 255, 213)
}

.notification_modal1 > div {
  padding: 10px 0;
  border-bottom: 1px solid #f3f3f3;
}
.notification_modal2 {
  position: fixed; 
 /* left: 0%; */
  /* font-weight: normal !important; */
 background: transparent;
 /* padding: 10px 20px; */
 box-shadow: 5px 3px 22px #958e8e29;
 /* width: 100%;  */
 /* max-height: 500px;  */
 color: #393939; 
 display: block;
  width: 100%;
 height: 100%;
  top: 0px;
  left: 0;
  /* padding:50px; */

 background-color:#1C811C66;
 /* position: relative; */
 z-index: 900;
 /* padding: 100px 30%; */
 overflow: auto;
 /* z-index: 1000;  */
}

.notification_footer {
  border-bottom: none !important;
  padding: 15px 0 !important;
  color: #fce9a0 !important;
}
/* Reminder Modal */
.reminder_modal {
  position: absolute;
  left: 8%;
  border-radius: 2px;
  background: white;
  padding: 20px 25px;
  box-shadow: 5px 3px 22px #958e8e29;
  width: fit-content;
  color: #393939;

  z-index: 9;
}
.reminder_modal .alarm_icon {
  width: 24px;
  height: 24px;
}
.reminder_modal .rdrDefinedRangesWrapper {
  display: none;
}
/* Extra styles */
.half_width {
  width: 50% !important;
}
.back_drop {
  background: rgba(247, 249, 250, 0.705);
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 82px;
  left: 0;
}
.light_gray {
  color: #a9aab1 !important;
}
.white_text {
  color: white !important;
}
.save_button {
  background-color: #1c811c;
  width: 100%;
  padding: 10px;
}
.app_input {
  border-bottom: 1px solid #c6c6c6;
  padding: 6px;
  width: 100%;
}
.bold {
  font-weight: bold !important;
  font-family: sans-serif !important;
}
.normal {
  font-weight: 500 !important;
}
.pointer {
  cursor: pointer;
}
.drop_down {
  background: #1c811c !important;
  padding: 10px;
  position: relative;
  border-radius: 3px !important;
}
.drop_down button {
  background: none !important;
  color: white;
}
.drop_down_menu {
  background: white;
  position: absolute;
  width: 100%;
  left: 0;
  padding: 10px 10px 0 10px;
  border-radius: 3px;
  border: 1px solid #e6e6e6;
}
.drop_down_menu p {
  margin-bottom: 10px;
  cursor: pointer;
}
.drop_down_menu p:hover {
  color: #1c811c;
}

.content {
  /* max-width: 1300px; */
  margin: 0 auto;
  padding: 20px;
  flex-wrap: wrap;
  display: flex;
}
.widget{
  position:fixed;
  right:15px;
  bottom:0;
  /* width:100px; */
  z-index: 1000;

}
/* .widget input[type='text']:hover, widget input[type='text']:focus, widget input[type='text']:active{
border-bottom:2px solid #25d366 !important;
padding:5px
} */

.widget a{
  text-decoration: none;
  color:black;
}
.widget-transition{
  transition: all 0.5s ease-out;
  background-color: rgba(37, 211, 102, 1);
  min-width: 200px;

  /* background-color: rgba(37, 211, 102, 0.6); */
}

.widget-transition:hover{
  transition: all 0.5s ease-out;
  background-color: rgba(37, 211, 102, 1);
  min-width: 200px;

}
.widget-transition2{
  transition: all 0.5s ease-out;
  background-color: rgba(37, 211, 102, 1);
}
.messageUs:hover{
  color: rgba(37, 211, 102, 1);

}
.messageUs{
  cursor:pointer;
  margin-top: 15px;
}
footer {
  position: relative;
  background-color: #454545;
  color: #ddd;
  height: 58px;
}

div.banner {
  width: 100%;
  padding: 130px 0 100px;
  background-size: cover;
  background-blend-mode: luminosity;
  background-image: url("../images/banner.png");
  background-color: #1c811c55;
  background-position-y: 20%;
}

div.banner .login {
  margin: 0 auto;
  max-width: 600px;
  padding: 40px;
  position: unset;
  border-radius: 10px;
  background-color: #fffffff0;
}
div.login {
  display: flex;
}
div.login .coat-of-arm {
  border-right: 1px solid #c6c6c6;
  padding-top: 15px;
  padding-right: 40px;
  width: 270px;
}
div.login .coat-of-arm img {
  width: 140px;
  height: auto;
}
div.login .coat-of-arm h2 {
  font-size: 1.58rem;
  font-family: sans-serif;
  line-height: 1.5rem;
  font-weight: 700;
}
div.login .coat-of-arm h4 {
  font-size: 1.25rem;
  font-weight: 400;
}
div.login .form {
  padding-left: 40px;
  width: 100%;
  text-align: center;
}
.login-form-group {
  width: 100%;
  margin-bottom: 20px;
}
.login-form-group {
  width: 100%;
  margin-bottom: 15px;
}
.login-form-group input {
  width: 100%;
  padding: 13px;
  border: 1px solid #c6c6c6;
  border-radius: 5px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 0.9rem;
  color: #777;
}
.login-form-groupz {
  /* width: 100%; */
  /* padding: 13px; */
  /* border: 1px solid #c6c6c6; */
  /* border-radius: 5px; */
  /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
  /* font-size: 0.9rem; */
  /* padding: 13px 0;
  color: #777;
  position: absolute; */
  /* top:13px; */
  /* right: 0; */
  /* margin:0 13px */
cursor: pointer;
  width: 10%;
  height: 100%;
  padding: 13px 0;
  position: absolute;
  right:0;
  top: 0px;
  border:none ;
   display: flex;
   align-items: center;
   opacity:"0.3";
  /* border: 1px solid #c6c6c6; */
  /* border-radius: 5px; */
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 0.9rem;
  color: #777;


}
div.login .form button {
  width: 100%;
  color: #fff;
  padding: 13px;
  background-color: #017d34;
  border-radius: 5px;
  outline: none;
  border: none;
  transition: all 0.3s ease-out;
}
div.login .form button:hover {
  background-color: #017d3488;
}
.intro {
  max-width: 800px;
  margin: 30px auto 10px;
}
div.dept-news {
  background-color: #ffffff;
  box-shadow: 5px 3px 22px #958e8e29;
  border: 1px solid #c6c6c6;
  border-radius: 10px;
  padding: 20px;
  position: relative;
}
div.dept-news h4 {
  background-color: #1c811c;
  color: #ffffff;
  padding: 5px 10px 2px;
  display: inline-block;
  position: relative;
  right: 20px;
}
.headline {
  align-items: center;
  border-bottom: 1px solid #c6c6c6;
  padding-bottom: 20px;
}
.headline:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.headline .date {
  background-color: #1c811c1f;
  padding: 5px 10px;
  text-align: center;
}
.headline .date .m {
  font-size: 0.7rem;
}
.headline .date .d {
  font-size: 1.4rem;
}
.headline .title {
  margin-left: 10px;
}
div.bottom {
  border-left: 1px solid #c6c6c6;
  padding: 0 20px;
}
div.bottom h2 {
  color: #1c811c;
  font-family: sans-serif;
  margin-bottom: 20px;
}
div.bottom ol {
  padding-left: 20px;
}
div.bottom ul {
  padding: 0;
  list-style: none;
}

@media only screen and (max-width: 900px) {
  .col-8,
  .col-7,
  .col-6 {
    width: 100%;
    flex: 1 0 100%;
  }
  .col-5,
  .col-4,
  .col-3 {
    width: 50%;
    flex: 1 0 50%;
  }
}

@media only screen and (max-width: 600px) {
  .col-5,
  .col-4,
  .col-3 {
    width: 100%;
    flex: 1 0 100%;
  }
  div.banner {
    padding-top: 60px;
  }
  div.login {
    display: block;
    margin: 20px !important;
  }
  div.login .coat-of-arm {
    border-right: none;
    padding-bottom: 20px;
    padding-right: 0;
    text-align: center;
    width: 100%;
  }
  div.login .form {
    padding-left: 0;
  }

  .flex-align{
    display: flex;
    /* align-items: center; */
  }
  .flex-md-row {
    flex-direction: column;
  }
  .w-md-75 {
    width: 100% !important;
  }
}
.statsicon {
  width: 130px;
  height: 35px;
}

.eventnote {
  width: 130px;
  height: 35px;
}
